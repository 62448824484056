<template>
  <accordion>
    <template v-slot:heading>
      <h5 class="faq__heading u-text--bold u-fs-14 u-fs-sm-16">
        <span class="faq__icon">Q</span>
        {{ heading }}
      </h5>
    </template>
    <template v-slot:body>
      <div class="faq__body u-fs-14 u-fs-sm-16">
        <span class="faq__icon">A</span>
        <div>
          <slot name="body" />
        </div>
      </div>
    </template>
  </accordion>
</template>
<script>
  export default {
    components: {
      'accordion': require('../component/Accordion.vue').default,
    },
    props: {
      heading: {
        type: String,
        required: true,
      },
    },
    methods: {

    },
  }
</script>
<style lang="scss" scoped>
  @import '@/scss/foundation/settings';
  @import '@/scss/foundation/functions';
  @import '@/scss/foundation/mixins';
  .faq {
    &__icon {
      display: inline-block;
      flex: 0 0 40px;
      text-align: center;
      font-weight: bold;
    }
    &__heading {
      display: flex;
      column-gap: 20px;
      @include mq(sm) {
        column-gap: 10px;
      }
    }
    &__body {
      display: flex;
      column-gap: 20px;
      @include mq(sm) {
        column-gap: 10px;
      }
    }
  }
</style>
