<template>
  <div class="comment">
    <div class="comment-header">
      <div class="comment-write">
        <button class="comment-write__btn" @click="modalShow()">
          <i class="icon-pencil u-mr-10" />コメントを書く
        </button>
      </div>
    </div>
    <transition-group tag="div" v-if="comments.length > 0" class="comment-list" name="comment-animation">
      <div class="comment-item" v-for="post in limitPosts" :key="post.id">
        <div class="comment-item-header">
          <p class="comment-item-header__date">{{ formatDate(post.date) }}</p>
          <p v-if="post.nickname" class="comment-item-header__username">{{ post.nickname }}</p>
          <p v-else class="comment-item-header__username">ゲストさん</p>
        </div>
        <div class="comment-item-body">
          <nl2br tag="p" :text="post.comment" />
        </div>
      </div>
    </transition-group>
    <div class="u-text--center u-mt-30 u-mt-sm-60" v-if="showPost.view < comments.length">
      <div class="u-animation--fade-up u-animation-sm--none">
        <button class="c-btn--product" @click="moreposts()">もっと見る</button>
      </div>
    </div>
    <modal name="commentform">
      <template slot="title">コメントを書き込む</template>
      <div slot="content">
        <p class="u-mb-40 u-fs-14 u-fs-sm-16">*コメント欄にはニックネームのみ表示されます。</p>
        <form action="">
          <div class="c-form-group">
            <div class="c-form__label">
              <p><span class="c-form__badge">任意</span></p>
              <p>ニックネーム（20字以内）</p>
            </div>
            <p v-for="(error, index) in errors.nickname" class="u-fs-14 u-fs-sm-16 c-form__error">{{ error }}</p>
            <input v-model="params.nickname" type="text" class="c-form-control u-fs-16 u-fs-sm-18" placeholder="プレミアム花子">
          </div>
          <div class="c-form-group">
            <div class="c-form__label">
              <p><span class="c-form__badge">任意</span></p>
              <p>年代</p>
            </div>
            <p v-for="(error, index) in errors.age_id" class="u-fs-14 u-fs-sm-16 c-form__error">{{ error }}</p>
            <div class="c-custom-select">
              <select v-model="params.age_id" class="c-form-control u-fs-16 u-fs-sm-18">
                <option value="">選択してください</option>
                <option v-for="(age, index) in this.ages" :value="index">{{ age }}</option>
              </select>
            </div>
          </div>
          <div class="c-form-group">
            <div class="c-form__label">
              <p><span class="c-form__badge">任意</span></p>
              <p>性別</p>
            </div>
            <p v-for="(error, index) in errors.sex_id" class="u-fs-14 u-fs-sm-16 c-form__error">{{ error }}</p>
            <div class="c-custom-select">
              <select v-model="params.sex_id" class="c-form-control u-fs-16 u-fs-sm-18">
                <option value="">選択してください</option>
                <option v-for="(sex, index) in this.sexes" :value="index">{{ sex }}</option>
              </select>
            </div>
          </div>
          <div class="c-form-group">
            <div class="c-form__label">
              <p><span class="c-form__badge">任意</span></p>
              <p>地域</p>
            </div>
            <p v-for="(error, index) in errors.area_id" class="u-fs-14 u-fs-sm-16 c-form__error">{{ error }}</p>
            <div class="c-custom-select">
              <select v-model="params.area_id" class="c-form-control u-fs-16 u-fs-sm-18">
                <option value="">選択してください</option>
                <option v-for="(area, index) in this.areas" :value="index">{{ area }}</option>
              </select>
            </div>
          </div>
          <div class="c-form-group">
            <div class="c-form__label">
              <p><span class="c-form__badge -required">必須</span></p>
              <p>コメント記入欄（200字以内）</p>
            </div>
            <p v-for="(error, index) in errors.content" class="u-fs-14 u-fs-sm-16 c-form__error">{{ error }}</p>
            <textarea v-model="params.content" class="c-form-control"></textarea>
          </div>
          <div class="u-mb-30 u-mb-sm-40">
            <h5 class="u-fs-14 u-fs-sm-16 u-text--bold u-mb-10">ご注意</h5>
            <ul class="u-fs-12 u-fs-sm-14">
              <li class="u-mb-10">こちらはご意見・ご感想欄です。ご質問については回答いたしかねますので、何卒ご了承ください。</li>
              <li class="u-mb-10">ご投稿内容・ご投稿者さまの情報は、個人が特定されない形で、セブンプレミアムが運営するメディア向上委員会や広告媒体に掲載させていただく場合がございます。</li>
              <li class="u-mb-10">ご投稿内容が誹謗中傷や特定の方同士の連続したやり取りなど、 サイトの趣旨にそぐわない場合は掲載を控えさせていただくことがあります。</li>
            </ul>
          </div>
          <div class="agree">
            <div class="agree">
              <div class="agree__header">
                <p><a :href="this.termUrl" target="_blank" rel="noopener">ご利用規約</a>に同意して送信してください。</p>
              </div>
              <div class="agree__body">
                <div class="c-custom-checkbox">
                  <input v-model="agree" type="checkbox" id="agree-checkbox">
                  <label for="agree-checkbox">ご利用規約に同意する</label>
                </div>
              </div>
            </div>
          </div>
          <div class="u-mt-30 u-mt-sm-40 u-text--center">
            <button @click.stop="commentAdd" :class="{ 'disabled': !agree || !params.content }" type="button" class="c-btn--product">コメントを書き込む</button>
          </div>
        </form>
      </div>
    </modal>
    <modal name="commentComplete">
      <template slot="title">コメントの投稿が完了しました。</template>
      <div slot="content">
        <p class="u-mb-40 u-fs-14 u-fs-sm-16">コメントのご投稿ありがとうございます。掲載されるまでしばらくお待ちください。<br>
            掲載されるまでに日数をいただく場合があります。また、掲載されない場合もありますので、あらかじめご了承ください。</p>
      </div>
    </modal>
  </div>
</template>
<script>
  import moment from 'moment';
  import _ from 'lodash';
  import axios from "axios";
  export default {
    props: {
      comments: {
        type: Array,
        required: true,
      },
      sexes: {
        type: [Array,Object],
        required: true,
      },
      ages: {
        type: [Array,Object],
        required: true,
      },
      areas: {
        type: [Array,Object],
        required: true,
      },
      productId: {
        type: Number,
        default: 0,
      },
      termUrl: {
          type: String,
          required: true
      }
    },
    data() {
      return {
        activeIndex: 0,
        posts: [],
        showPost: {
          default: 3,
          view: 3,
          add: 3,
        },
        params: {
          product_id: '',
          nickname: '',
          age_id: '',
          sex_id: '',
          area_id: '',
          content: '',
        },
        agree: false,
        errors: {}
      }
    },
    computed: {
      limitPosts() {
        const posts = this.posts;
        return posts.slice(0, this.showPost.view);
      }
    },
    mounted() {
      this.posts = this.comments;
      this.params.product_id = this.productId;
    },
    methods: {
      setActiveIndex(index) {
        this.activeIndex = index;
      },
      sortPost(order, index) {
        this.setActiveIndex(index);
        this.resetShowPostView();
        if(order === 'new') {
          this.posts = _.sortBy(this.posts, row => {
            return new Date(row.date);
          });
        } else if(order === 'popular') {
          this.posts = _.sortBy(this.posts, 'popular').reverse();
        }
      },
      resetShowPostView() {
        this.showPost.view = this.showPost.default;
      },
      formatDate(date) {
        return moment(date).format("YYYY.MM.DD");
      },
      moreposts() {
        this.showPost.view += this.showPost.add;
      },
      modalShow() {
        this.$modal.show('commentform');
      },
      completeModalShow() {
        this.$modal.hide('commentform');
        this.$modal.show('commentComplete');
        setTimeout(() => {
          location.reload();
        },3000);
      },
      async commentAdd() {
        // 商品コメントAPI接続
        await axios.post('/product/comment/add', this.params).then(res => {
          if (res.data.success) {
            this.completeModalShow();
          } else {
              this.errors = res.data.errors;
          }
        }).catch(err => {
          console.log('err', err);
        });
      }
    }
  }
</script>
<style lang="scss" scoped>
  @import '@/scss/foundation/settings';
  @import '@/scss/foundation/functions';
  @import '@/scss/foundation/mixins';

  $theme-color: map-get($colors, product);
  .comment-header {
    display: flex;
    justify-content: space-between;
    @include mq(md) {
      justify-content: flex-start;
      flex-flow: row wrap;
      row-gap: 30px;
    }
  }
  .comment-sort {
    display: flex;
    @include mq(md) {
      order: 2;
      flex: 0 0 100%;
    }
    &__btn {
      border: 1px solid $theme-color;
      padding: 12px;
      font-weight: bold;
      font-size: rem(16);
      width: 200px;
      color: $theme-color;
      @include mq(md) {
        flex: 0 0 50%;
        width: auto;
      }
      & + & {
        border-left: none;
      }
      &:first-child {
        border-radius: 2px 0 0 2px;
      }
      &:last-child {
        border-radius: 0 2px 2px 0;
      }
      &.is-active {
        border-color: transparent;
        background: $theme-color;
        color: #fff;
        pointer-events: none;
      }
      @include mq_min(lg) {
        &:not(.is-active) {
          transition: color .2s;
          &:hover, &:focus {
            color: rgba($theme-color, .7);
          }
        }
      }
      @include mq(sm) {
        font-size: rem(14);
      }
    }
  }
  .comment-write {
    margin-left: auto;
    flex: 0 0 200px;
    @include mq(md) {
      order: 1;
      flex: 0 0 100%;
      text-align: center;
    }
    &__btn {
      width: 100%;
      border-radius: 30px;
      font-weight: bold;
      color: $theme-color;
      border: 1px solid $theme-color;
      padding: 12px;
      @include mq_min(lg) {
        i {
          transition: transform .5s ease(OutCirc);
          display: inline-block;
        }
        &:hover, &:focus {
          i {
            transform: rotate(-35deg);
          }
        }
      }
      @include mq(md) {
        max-width: 340px;
      }
      @include mq(sm) {
        max-width: 240px;
        font-size: rem(14);
      }
    }
  }
  .comment-list {
    display: grid;
    grid-template: 1fr auto;
    row-gap: 30px;
    margin-top: 30px;
    @include mq(sm) {
      row-gap: 20px;
      margin-top: 20px;
    }
  }
  .comment-item {
    border: 1px solid #E5E5E5;
    &-header {
      background: #F8F8F8;
      border-bottom: 1px solid #E5E5E5;
      padding: 20px;
      display: flex;
      align-items: baseline;
      line-height: 1;
      @include mq(sm) {
        padding: 10px 15px;
        align-items: baseline;
      }
      &__date {
        font-size: rem(14);
        @include mq(sm) {
          font-size: rem(12);
        }
      }
      &__username {
        font-size: rem(18);
        font-weight: bold;
        color: $theme-color;
        border-left: 1px solid #ddd;
        padding-left: 15px;
        margin-left: 15px;
        @include mq(sm) {
          font-size: rem(16);
          padding-left: 0;
          margin-left: 10px;
          border-left: 0;
        }
      }
    }
    &-body {
      padding: 30px;
      @include mq(sm) {
        padding: 15px;
        font-size: rem(14);
      }
    }
  }
  .comment-animation {
    &-enter {
      opacity: 0;
      &-active {
        transition: .8s;
      }
      &-to {
        opacity: 1;
      }
    }
    &-leave {
      opacity: 1;
      &-active {
        transition: .3s;
      }
      &-to {
        opacity: 0;
      }
    }
  }
  .agree {
    &__header {
      text-align: center;
      padding: 20px 40px;
      @include mq(sm) {
        padding: 0 0 15px;
        font-size: rem(14);
      }
      a {
        color: $theme-color;
        text-decoration: underline;
        &:hover, &:focus {
          text-decoration: none;
        }
      }
    }
    &__body {
      background: #F5F5F5;
      padding: 20px 40px;
      text-align: center;
      @include mq(sm) {
        padding: 20px;
        font-size: rem(14);
      }
    }
  }
  .c-btn {
    &--product.disabled {
      pointer-events: none;
    }
  }
  .c-form__error {
    color: #E60012;
    margin-bottom: 5px;
  }
</style>
