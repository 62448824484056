<template>
  <div>
    <label v-if="label !== ''" class="u-text--normal u-text-sm--bold u-mb-5 u-mb-sm-10 u-fs-14 u-fs-sm-16 d-block">{{ label }}</label>
    <div class="c-custom-select">
      <select v-model="innerSelected" class="c-form-control u-fs-14 u-fs-sm-18">
        <option v-for="(option, i) in options" :key="i" :value="option.value">
          <template v-if="option.level !== undefined && option.level === 2">
            　{{ option.text }}
          </template>
          <template v-else>
            {{ option.text }}
          </template>
        </option>
      </select>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      queryname: {
        type: String,
        required: true,
      },
      options: {
        type: Array,
        required: true,
      },
      label: {
        type: String,
      },
      selected: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
      }
    },
    computed: {
      innerSelected: {
        get() {
          return this.$props.selected;
        },
        set(value) {
          const url = new URL(window.location.href);
          url.searchParams.delete(this.queryname);
          if (value !== '') {
            url.searchParams.append(this.queryname, value);
          }
          // current_page初期化
          url.searchParams.set('page', 1);
          window.location.href = url;
        }
      }
    }
  }
</script>
