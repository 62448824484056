<template>
  <div id="d3Container" ref="canvas"></div>
</template>
<script>

  import * as THREE from 'three';
  import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js';
  import {PLYLoader} from 'three/examples/jsm/loaders/PLYLoader.js';
  import {PCDLoader} from 'three/examples/jsm/loaders/PCDLoader';
  import {OBJLoader} from 'three/examples/jsm/loaders/OBJLoader';
  import {STLLoader} from 'three/examples/jsm/loaders/STLLoader';
  import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";

  export default {
    props: {
      ossPath: {
        type: String,
        default() {
          return ''
        }
      },
      suffix: {
        type: String,
        default() {
          return 'gltf'
        }
      },
      AutoFresh: {
        type: Boolean,
        default() {
          return true
        }
      },
      autoAnimate: {
        type: Boolean,
        default() {
          return true
        }
      },
      currentColor: {
        type: String,
        default() {
          return ''
        }
      },
      matchedColor: {
        type: String,
        default() {
          return ''
        }
      },
      matchedOssPatch: {
        type: String,
        default() {
          return ''
        }
      },
      showMatchWatch: {
        type: Boolean,
        default() {
          return false
        }
      },
    },
    data() {
      return {
        loading: false,
        publicPath: process.env.BASE_URL,
        mesh: null,
        camera: null,
        scene: null,
        originX: 20,
        originY: 0,
        originZ: 20,
        renderer: null,
        controls: null,
      }
    },
    mounted() {
      this.init();
    },
    watch: {
      ossPath(val, oldVal) {
        if (val != oldVal) {
          this.init()
        }
      },
      AutoFresh(val, oldVal) {
        if (val) {
          this.init()
        } else {
          this.destroyed();
        }
      },
      showMatchWatch(val, oldVal) {
        this.init()
      },
      currentColor(val, oldVal) {
        if (val != oldVal) {
          this.init()
        }
      }
    },
    methods: {
      destroyed() {
        this.clear();
      },
      init() {
        if (!this.showMatchWatch) {
          this.matchedOssPatch = ''
        }
        this.createScene();
        this.loadLoader();
        this.createLight();
        this.createCamera();
        this.createRender();
        this.createControls();
        this.render();
      },
      clear() {
        this.mesh = null
        this.camera = null
        this.scene = null
        this.renderer = null
        this.controls = null
        cancelAnimationFrame(this.animationId)
      },
      createScene() {
        this.loading = true;
        this.scene = new THREE.Scene();
      },
      loadLoader() {
        let loader = new GLTFLoader();
        loader.load(this.ossPath, geometry => {
          this.loading = false;
          this.mesh = geometry.scene;
          this.mesh.scale.set(3, 3, 3);
          this.mesh.position.set(0, 0, 0);
          this.mesh.rotation.x = Math.PI / 2;
          this.scene.add(this.mesh);
        })
      },
      createLight() {
        let pointColor = '#ffffff';
        const ambientLight = new THREE.AmbientLight(0xffffff, 1.0);
        this.scene.add(ambientLight);

        const spotLight = new THREE.SpotLight(0xffffff);
        spotLight.position.set(50, 50, 50);
        spotLight.castShadow = false;
        spotLight.receiveShadow = false;
        this.scene.add(spotLight);
      },
      createCamera() {
        const element = this.$refs.canvas;
        const width = element.clientWidth;
        const height = element.clientHeight;
        this.cWidth = width;
        this.cHeight = height;
        const k = width / height;
        this.aspect = k;
        this.camera = new THREE.PerspectiveCamera(35, k, 1, 10000);
        this.camera.position.set(this.originX, this.originY, this.originZ);
        this.camera.up.set(0, 0, 1);
        this.camera.lookAt(new THREE.Vector3(this.originX, this.originY, this.originZ));
        this.scene.add(this.camera)
      },
      // 创建渲染器
      createRender() {
        const element = this.$refs.canvas;
        this.renderer = new THREE.WebGLRenderer({antialias: true, alpha: true, preserveDrawingBuffer: true});
        this.renderer.setSize(element.clientWidth, element.clientHeight);
        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        this.renderer.setClearColor(new THREE.Color(0xffffff));
        element.innerHTML = '';
        element.appendChild(this.renderer.domElement);
      },
      render() {
        this.animationId = requestAnimationFrame(this.render);
        this.renderer.render(this.scene, this.camera);
        this.controls.update();
      },
      createControls() {
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      },
      onWindowResize() {
        this.camera.aspect = this.aspect;
        this.camera.updateProjectionMatrix();
      }
    }
  }
</script>
<style scoped>
  #d3Container {
    width: 100%;
    height: 600px;
    z-index: 888;
  }
</style>
