<template>
  <div v-if="size === 'sm'" class="share">
    <div class="share__item">
      <a :href="`https://twitter.com/share?ref_src=${url}`" class="twitter-share-button" data-show-count="false">Tweet</a>
    </div>
    <div class="share__item">
      <div class="fb-share-button" :data-href="url" data-layout="button" data-size=""></div>
    </div>
    <div class="share__item">
      <div class="line-it-button" data-lang="ja" data-type="share-a" data-env="REAL" :data-url="url" data-color="default" data-size="small" data-count="false" data-ver="3" style="display: none;"></div>
    </div>
  </div>
  <div v-else-if="size === 'lg'" class="share-lg">
    <div class="u-animation--fade-up">
      <h5 class="share-lg__heading">この記事をシェアする</h5>
    </div>
    <div class="share-lg__row">
      <div class="share__item">
        <a :href="`https://twitter.com/share?ref_src=${url}`" class="twitter-share-button" data-show-count="false">Tweet</a>
      </div>
      <div class="share__item">
        <div class="fb-share-button" :data-href="url" data-layout="button" data-size=""></div>
      </div>
      <div class="share__item">
        <div class="line-it-button" data-lang="ja" data-type="share-a" data-env="REAL" :data-url="url" data-color="default" data-size="small" data-count="false" data-ver="3" style="display: none;"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    size: {
      type: String,
      default: 'sm',
    }
  },
  data() {
    return {
      url: ''
    }
  },
  mounted() {
    this.url = location.href;
    this.setX();
    this.setFacebook();
    this.setLine();
  },
  methods: {
    setX() {
      const xScript = document.createElement('script');
      xScript.src = '//platform.twitter.com/widgets.js';
      xScript.charset = 'utf-8';
      xScript.defer = true;
      document.head.appendChild(xScript);
    },
    setFacebook() {
      const FacebookScript = document.createElement('script');
      FacebookScript.src = '//connect.facebook.net/ja_JP/sdk.js#xfbml=1&version=v19.0';
      FacebookScript.defer = true;
      document.head.appendChild(FacebookScript);
    },
    setLine() {
      const lineScript = document.createElement('script');
      lineScript.src = '//www.line-website.com/social-plugins/js/thirdparty/loader.min.js';
      lineScript.defer = true;
      document.head.appendChild(lineScript);
    }
  }
}
</script>
<style lang="scss">
@import '@/scss/foundation/settings';
@import '@/scss/foundation/functions';
@import '@/scss/foundation/mixins';
.share {
  border-top: 1px solid #E5E5E5;
  margin-top: 40px;
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  &__item {
    font-size: rem(13);
    display: inline-flex;
    align-items: center;
    @include mq(sm) {
      font-size: rem(11);
    }
    &:not(:first-child) {
      border-left: 1px solid #E5E5E5;
      margin-left: 10px;
      padding-left: 10px;
    }
  }
  &__btn {
    display: inline-flex;
    align-items: center;
    vertical-align: bottom;
    @include mq_min(lg) {
      transition: opacity .3s;
      &:hover, &:focus {
        opacity: .7;
      }
    }
    &.-twitter {
      color: #000;
      img {
        @include mq_min(sm) {
          width: 24px;
        }
      }
    }
    &.-facebook {
      color: #3B5998;
    }
    &.-line {
      color: #00B900;
    }
    img {
      vertical-align: bottom;
      line-height: 1;
      @include mq(sm) {
        width: 20px;
      }
    }
  }
}
.share-lg {
  background: #F9F9F9;
  padding: 30px 80px;
  margin-top: 100px;
  @include mq(lg) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @include mq(sm) {
    margin-top: 60px;
  }
  &__heading {
    font-weight: bold;
    font-size: rem(20);
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mq(sm) {
      font-size: rem(16);
    }
    &::before, &::after {
      content: '';
      display: block;
      width: 3px;
      height: 1.5em;
      background: #222;
      border-radius: 2px;
    }
    &::before {
      transform: rotate(-35deg);
      margin-right: 20px;
    }
    &::after {
      transform: rotate(35deg);
      margin-left: 20px;
    }
  }
  &__row {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__btn {
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: rem(18);
    font-weight: bold;
    color: #fff;
    @include mq(sm) {
      padding: 15px;
      font-size: rem(16);
      img {
        width: 20px;
      }
    }
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 5px;
      background: rgba(#000, .1);
    }
    @include mq_min(lg) {
      transition: opacity .3s;
      &:hover, &:focus {
        opacity: .8;
      }
    }
    &.-twitter {
      background: #000;
      img {
        @include mq_min(sm) {
          width: 30px;
        }
      }
    }
    &.-facebook {
      background: #3B5998;
    }
    &.-line {
      background: #00B900;
    }
  }
}
</style>
