<template>
  <div class="recipe">
    <hooper ref="hooper" :settings="hooperSettings" @beforeSlide="onBeforeSlide" @slide="onSlide" @afterSlide="onAfterSlide">
      <slide v-for="(slide, i) in items" :key="i">
        <div class="c-card--recipe -border">
          <a :href="slide.url" class="c-card__inner -scale-image">
            <figure class="c-card__image">
              <img v-if="slide.image_alt" :src="slide.image" :alt="slide.image_alt">
              <img v-else :src="slide.image" :alt="slide.title">
            </figure>
            <div class="u-px-10">
              <p class="recipe__date">{{slide.date}}</p>
              <p class="recipe__title">{{slide.title}}</p>
            </div>
          </a>
        </div>
      </slide>
      <hooper-pagination slot="hooper-addons" mode="fraction"></hooper-pagination>
    </hooper>
    <div class="controller">
      <button type="button" class="controller__prev" @click.prevent="slidePrev">
        <i class="icon-left" />
      </button>
      <button type="button" class="controller__next" @click.prevent="slideNext">
        <i class="icon-right" />
      </button>
    </div>
  </div>
</template>
<script>
  import {
    Hooper,
    Slide,
    Pagination as HooperPagination
  } from 'hooper';

  export default {
    props: {
      items: Array,
    },
    components: {
      Hooper, Slide, HooperPagination
    },
    data() {
      return {
        hooperSettings: {
          itemsToShow: 2,
          infiniteScroll: true,
          autoPlay: true,
          mouseDrag: false,
          touchDrag: true,
          wheelControl: false,
          keysControl: false,
          playSpeed: 6000,
          transition: 800,
          hoverPause: false,
          centerMode: true,
          breakpoints: {
            640: {
              itemsToShow: 3,
              centerMode: false,
            },
            1000: {
              itemsToShow: 4,
              centerMode: false,
            },
          }
        },
      }
    },
    mounted() {
    },
    methods: {
      onBeforeSlide(slide) {
      },
      onSlide(slide) {
      },
      onAfterSlide(slide) {
      },
      slidePrev() {
        this.$refs.hooper.slidePrev();
        this.$refs.hooper.restartTimer();
      },
      slideNext() {
        this.$refs.hooper.slideNext();
        this.$refs.hooper.restartTimer();
      }
    }
  }
</script>
<style lang="scss">
  .hooper-slide {
    padding-left: 5px;
    padding-right: 5px;
    height: auto;
  }
  .hooper-list {
    padding-bottom: 40px;
    @media (max-width: 1000px) {
      padding-left: 0;
      padding-bottom: 60px;
    }
  }
  .hooper-indicator {
    width: 10px;
    height: 10px;
    background-color: #A1A1A1;
    border-radius: 50%;
    margin: 0 6px;
    &:hover, &.is-active {
      background: #008837;
    }
  }
</style>
<style lang="scss" scoped>
  @import '@/scss/foundation/settings';
  @import '@/scss/foundation/functions';
  @import '@/scss/foundation/mixins';
  .recipe {
    position: relative;
    &__pc {
      @include mq(sm) {
        display: none;
      }
    }
    &__sp {
      display: none;
      @include mq(sm) {
        display: block;
      }
    }
    &__layout {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: auto;
      row-gap: 40px;
      column-gap: 40px;
      @include mq(lg) {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 30px;
        column-gap: 30px;
      }
      &.-pickup {
        .c-card--recipe {
          &:first-child {
            grid-column: 1 / 5;
            display: flex;
            width: 100%;
            @include mq(lg) {
              grid-column: 1 / 3;
            }
            .c-card {
              &__inner {
                display: flex;
                padding-bottom: 0;
                & > div {
                  flex: 1 1 auto;
                  padding: 20px 80px 0 40px;
                  @include mq(lg) {
                    padding: 10px 40px 0 30px;
                  }
                }
              }
              &__image {
                flex: 0 1 385px;
                @include mq(lg) {
                  flex: 0 0 280px;
                }
              }
            }
            .recipe__date {
              font-size: rem(18);
              @include mq(xl) {
                font-size: rem(16);
              }
              @include mq(lg) {
                font-size: rem(14);
              }
            }
            .recipe__title {
              font-size: rem(32);
              @include mq(xl) {
                font-size: rem(30);
              }
              @include mq(lg) {
                font-size: rem(24);
              }
            }
          }
          @include mq(lg) {
            &:not(:first-child) {
              .c-card__inner {
                display: flex;
                padding-bottom: 0;
                & > figure {
                  flex: 0 0 140px;
                  margin-right: 15px;
                }
              }
            }
          }
        }
      }
    }
    &__date {
      font-size: rem(13);
      margin-top: 10px;
      @include mq(sm) {
        font-size: rem(13);
      }
    }
    &__title {
      font-size: rem(16);
      font-weight: bold;
      @include mq(sm) {
        font-size: rem(16);
      }
    }
  }
  .c-card {
    &__inner {
      padding: 0 0 40px;
    }
    &--recipe {
      height: 100%;
    }
  }
  .hooper {
    height: auto;
    @include mq(sm) {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .hooper-pagination {
    font-size: rem(13);
  }
  .controller {
    &__prev,
    &__next {
      width: 50px;
      height: 50px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: map-get($map: $colors, $key: recipe);
      background: rgba(#fff, .9);
      border-radius: 50%;
      position: absolute;
      top: 50%;
      box-shadow: 0 10px 10px rgba(#000, .1);
      @include mq(sm) {
        width: 40px;
        height: 40px;
        transform: none;
        top: 34vw;
        background: rgba(#EF8200, .8);
        color: #fff;
      }
    }
    &__prev {
      left: 0;
      transform: translate(-50%, -50%);
      @include mq(sm) {
        left: 10px;
        transform: translate(0, -50%);
      }
    }
    &__next {
      right: 0;
      transform: translate(50%, -50%);
      @include mq(sm) {
        right: 10px;
        transform: translate(0, -50%);
      }
    }
  }
</style>
