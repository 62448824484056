<template>
  <div class="categories">
    <div class="l-container -sm--nopadding u-mb-40">
      <div class="filter">
        <ul class="l-tags list-unstyled categories-tag">
          <li class="l-tags__item">
            <button class="c-btn-outline-sm--product" :class="{ 'is-active' : selected.length === 0 }" @click="clickAllCategory($event)">すべてのカテゴリー</button>
          </li>
          <li class="l-tags__item" v-for="(category, i) in categories" :key="i">
            <button class="c-btn-outline-sm--product" :class="{'is-active' : selected.includes(i)}" @click="clickCategory(i, $event)">{{ category.name }}</button>
          </li>
        </ul>
      </div>
    </div>
    <transition-group name="category-animation">
      <template v-for="(category, i) in categories">
        <section class="u-mb-40 u-mb-sm-80" :key="i" v-if="isShow(i)">
          <div class="l-container">
            <div class="category-header">
              <h3 class="c-heading-4--product u-mb-0">{{ category.name }}</h3>
              <p class="category-header__link">
                <a :href="category.url" class="c-link-text--product -icon--right u-text--bold">全ての{{ category.name }}一覧</a>
              </p>
            </div>
          </div>
          <div class="l-container -sm--nopadding">
            <div class="category-list">
              <div class="c-card--product -border category-card" v-for="(categoryLower, j) in category.categories" :key="j">
                <a :href="categoryLower.url" class="c-card__inner -scale-image">
                  <figure class="c-card__image">
                    <img :src="categoryLower.image" :alt="categoryLower.name">
                  </figure>
                  <div class="c-card__content-inner">
                    <p class="category-name">{{ categoryLower.name }}</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="l-container category-sp-link">
            <a :href="category.url" class="c-link-text--product -icon--right u-text--bold u-fs-12">全ての{{ category.name }}一覧</a>
          </div>
        </section>
      </template>
    </transition-group>
  </div>
</template>
<script>
  export default {
    props: {
      categories: Array,
    },
    components: {
    },
    data() {
      return {
        selected: [],
      }
    },
    mounted() {
    },
    methods: {
      clickAllCategory(e) {
        e.target.blur();
        this.selected = [];
      },
      clickCategory(index, e) {
        e.target.blur();
        if(!this.selected.includes(index)) {
          this.selected.push(index);
        } else {
          this.selected = this.selected.filter(v => {
            return v !== index;
          });
        }
      },
      isShow(index) {
        return (this.selected.length > 0) ? this.selected.includes(index) : true;
      }
    }
  }
</script>
<style lang="scss" scoped>
  @import '@/scss/foundation/settings';
  @import '@/scss/foundation/functions';
  @import '@/scss/foundation/mixins';

  .filter {
    background: #F8F8F8;
    padding: 20px;
    @include mq(sm) {
      padding: 10px 0;
      overflow-x: auto;
    }
  }
  .categories-tag {
    @include mq(sm) {
      margin: 0 0 0 15px;
      flex-flow: row nowrap;
      .l-tags__item {
        white-space: nowrap;
        font-size: rem(14);
        &:last-child {
          padding-right: 15px;
        }
      }
    }
  }
  .category-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    &__link {
      @include mq(sm) {
        display: none;
      }
    }
  }
  .category-sp-link {
    display: none;
    @include mq(sm) {
      display: block;
      text-align: right;
      margin-top: 30px;
    }
  }
  .category-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    row-gap: 24px;
    column-gap: 24px;
    @include mq(xl) {
      row-gap: 18px;
      column-gap: 18px;
    }
    @include mq(md) {
      grid-template-columns: repeat(3, 1fr);
    }
    @include mq(sm) {
      display: flex;
      overflow-x: auto;
      column-gap: 10px;
      & > * {
        flex: 0 0 35%;
        &:first-child {
          margin-left: 15px;
        }
        &:last-child {
          margin-right: 15px;
        }
      }
    }
  }
  .category-name {
    font-weight: bold;
    font-size: rem(16);
    @include mq(sm) {
      font-size: rem(12);
    }
  }
  .category-card {
    @include mq(sm) {
      border: 0;
      .c-card__content-inner {
        padding: 0 10px 0 0;
        margin-top: 10px;
      }
      &::after {
        content: none;
      }
    }
  }
  .category-animation {
    &-enter {
      opacity: 0;
      transform: translateY(30px);
      &-active {
        transition: .8s;
      }
      &-to {
        opacity: 1;
        transform: translateY(0);
      }
    }
    &-move {
      transition: transform .8s;
    }
    &-leave {
      opacity: 1;
      &-active {
        transition: .3s;
      }
      &-to {
        opacity: 0;
        transform: translateY(-30px);
      }
    }
  }
</style>
