<template>
  <vue-star ref="vuestar" animate="animated rubberBand">
    <div slot="icon">
      <button color="#008837" @click.prevent="handleClick" class="like-btn" :class="{'is-active': data.active, '-lg': size === 'lg'}">
        <i class="icon-heart" :class="{'-lg': size === 'lg'}" />
        <span class="like-btn__number" :class="{'-lg': size === 'lg'}">{{ data.number }}</span>
      </button>
    </div>
  </vue-star>
</template>
<script>
  import VueStar from 'vue-star';
  import axios from 'axios';
  export default {
    props: {
      number: {
        type: Number,
        default: 0,
      },
      productId: {
        type: Number,
        default: 0,
      },
      size: {
        type: String,
        default: 'sm',
      }
    },
    data() {
      return {
        data: {
          active: false,
          number: 0,
          product_id: 0,
        }
      }
    },
    components: {
      VueStar
    },
    mounted() {
      this.data.number = this.number;
      this.data.product_id = this.productId;

      this.supportCheck();
      let target = JSON.parse(localStorage.getItem('like_item'));
      if (target && target.includes(this.productId)) {
        this.data.active = true;
        this.data.number += 1;
      }
      this.$refs.vuestar.$data.active = this.data.active;
    },
    methods: {
      handleClick() {
        this.data.active = !this.data.active;
        this.data.number += (this.data.active) ? 1 : -1;

        if (this.data.active) {
          this.likeAdd();
        } else {
          this.likeCancel();
        }
      },
      supportCheck() {
        if (typeof localStorage === 'undefined') {
          alert('ご利用の環境ではいいねをご利用できません。恐れ入りますが、プライベートモードをご利用の場合は解除してからご利用ください。');
        }
      },
      async likeAdd() {
        // 商品いいねAPI接続
        await axios.post('/product/like/add', {
          'product_id': this.data.product_id
        }).then(res => {
          let array = [];
          if (localStorage.hasOwnProperty('like_item')) {
            array = JSON.parse(localStorage.getItem('like_item'));
          }
          array.push(this.data.product_id);
          localStorage.setItem('like_item', JSON.stringify(array));

        }).catch(err => {
          console.log('err', err);
        });
      },
      async likeCancel() {
        // 商品いいね解除API接続
        await axios.post('/product/like/cancel', {
          'product_id': this.data.product_id
        }).then(res => {
          let target = JSON.parse(localStorage.getItem('like_item'));
          let id = this.data.product_id;
          let array = target.filter(function(v) {
            return v !== id;
          });
          localStorage.setItem('like_item', JSON.stringify(array));

        }).catch(err => {
          console.log('err', err);
        });
      }
    }
  }
</script>
<style lang="scss">
  .VueStar__decoration {
    z-index: 10;
  }
</style>
<style lang="scss" scoped>
  @import '@/scss/foundation/settings';
  @import '@/scss/foundation/functions';
  @import '@/scss/foundation/mixins';
  .like-btn {
    $like-btn-color: #008837;
    $like-btn-color-active: #E81868;
    color: $like-btn-color;
    width: 60px;
    height: 60px;
    background: #fff;
    border: 1px solid $like-btn-color;
    border-radius: 50%;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    box-shadow: 0 5px 10px rgba(#000, .1);
    @include mq_min(lg) {
      transition: transform .5s ease(OutBack);
      backface-visibility: hidden;
      &:hover {
        transform: scale(1.1);
      }
    }
    @include mq(sm) {
      width: 50px;
      height: 50px;
    }
    &.-lg {
      width: 80px;
      height: 80px;
      @include mq(sm) {
        width: 60px;
        height: 60px;
      }
    }
    &.is-active {
      transition: .3s ease(OutCirc);
      color: #fff;
      background: $like-btn-color-active;
      border-color: transparent;
    }
    &__number {
      display: block;
      line-height: 1;
      font-size: rem(13);
      @include mq(sm) {
        font-size: rem(11);
      }
      &.-lg {
        font-size: rem(15);
        @include mq(sm) {
          font-size: rem(13);
        }
      }
    }
  }
  .icon-heart {
    font-size: rem(18);
    @include mq(sm) {
      font-size: rem(16);
    }
    &-lg {
      font-size: rem(22);
    }
  }
</style>
