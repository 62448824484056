<template>
  <div v-if="show" id="twitterTimeLine" class="twitter-timeline-component">
    <a
        class="twitter-timeline"
        data-lang="ja"
        data-width="100%"
        data-height="410"
        data-chrome="noheader nofooter"
        href="https://twitter.com/7premium_jp"></a>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        show: false
      }
    },
    mounted() {
      this.setTimeLine();
    },
    methods: {
      setTimeLine() {
        this.show = true;
        const twitterScript = document.createElement('script');
        twitterScript.setAttribute('src', '//platform.twitter.com/widgets.js');
        twitterScript.setAttribute('charset', 'utf-8');
        document.head.appendChild(twitterScript)

        let self = this;
        setTimeout(function() {
          try {
            const targetNode = document.getElementById('twitterTimeLine');
            const watchConfig = { attributes: false, childList: true, subtree: true };
            const observer = new MutationObserver(self.watchCallback);
            observer.observe(targetNode, watchConfig);
          } catch(e) {
            this.show = false;
            console.log(e);
          }
        }, 3000)
      },
      watchCallback(mutationsList, observer) {
        const iframe = document.getElementById('twitter-widget-0').contentWindow.document;
        const twitterStyle = iframe.createElement('style');
        const style = iframe.createTextNode('.timeline-Tweet-text {font-size: 14px !important;} .timeline-Header-title{font-size: 21px !important;}');
        twitterStyle.appendChild(style);
        iframe.getElementsByTagName('head')[0].appendChild(twitterStyle);
      }
    }
  }
</script>
<style lang="scss">
  @import '@/scss/foundation/settings';
  @import '@/scss/foundation/functions';
  @import '@/scss/foundation/mixins';

  .twitter-timeline-component {
    width: 640px;
    margin-left: auto;
    margin-right: auto;
    @include mq(sm) {
      display: none;
    }
  }
</style>
