<template>
  <div class="accordion">
    <div class="accordion__heading" @click="toggle" :class="{'is-active': active}">
      <slot name="heading" />
    </div>
    <transition name="animation">
      <div class="accordion__body p-richtext" v-if="active">
        <slot name="body" />
      </div>
    </transition>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        active: false,
      }
    },
    methods: {
      toggle() {
        this.active = !this.active;
      }
    }
  }
</script>
<style lang="scss">
  @import '@/scss/foundation/settings';
  @import '@/scss/foundation/functions';
  @import '@/scss/foundation/mixins';
  @keyframes animation {
    0% {
      opacity: 0;
      transform: translateY(-5px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .animation {
    &-enter {
      &-active {
        animation: animation .2s;
      }
    }
    &-leave {
      &-active {
        animation: animation .2s reverse;
      }
    }
  }
  .accordion {
    border-bottom: 1px solid #E5E5E5;
    &:first-child {
      border-top: 1px solid #e5e5e5;
    }
    &__heading {
      font-weight: bold;
      padding: 20px 70px 20px 20px;
      cursor: pointer;
      position: relative;
      @include mq_min(lg) {
        transition: .3s;
        &:hover, &:focus {
          background: #eee;
        }
      }
      @include mq(sm) {
        padding: 20px 45px 20px 0;
      }
      &::after {
        content: '＋';
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
      }
      &.is-active {
        &::after {
          content: '−';
        }
      }
    }
    &__body {
      padding: 20px;
      @include mq(sm) {
        padding: 20px 20px 20px 0;
      }
    }
  }
</style>
