<template>
  <div class="mainvisual">
    <hooper ref="hooper" :settings="hooperSettings" @beforeSlide="onBeforeSlide" @slide="onSlide" @afterSlide="onAfterSlide">
      <slide v-for="(slide, i) in slides" :key="i">
        <div class="c-card -nodots">
          <a :href="slide.url" class="c-card__inner -scale-image">
            <div class="card">
              <figure class="c-card__image">
                <img :src="slide.image" :alt="slide.title">
              </figure>
              <div class="card__inner">
                <p class="date">{{slide.date}}</p>
                <p class="title">{{slide.title}}</p>
              </div>
            </div>
          </a>
        </div>
      </slide>
      <hooper-pagination slot="hooper-addons"></hooper-pagination>
    </hooper>
    <div class="info">
      <div class="info__inner">
        <p class="info__date">{{ current.date }}</p>
        <p class="info__title">{{ current.title }}</p>
      </div>
    </div>
    <div class="controller">
      <button type="button" class="controller__prev" @click.prevent="slidePrev">
        <i class="icon-left" />
      </button>
      <button type="button" class="controller__next" @click.prevent="slideNext">
        <i class="icon-right" />
      </button>
    </div>
  </div>
</template>
<script>
  import {
    Hooper,
    Slide,
    Pagination as HooperPagination
  } from 'hooper';

  export default {
    props: {
      slides: Array
    },
    components: {
      Hooper, Slide, HooperPagination
    },
    data() {
      return {
        hooperSettings: {
          itemsToShow: 1,
          infiniteScroll: true,
          autoPlay: true,
          mouseDrag: false,
          touchDrag: true,
          wheelControl: false,
          keysControl: false,
          playSpeed: 6000,
          transition: 800,
          hoverPause: false,
          centerMode: true,
          breakpoints: {
            640: {
              itemsToShow: 2,
            },
            1000: {
              itemsToShow: 5,
              mouseDrag: false,
              touchDrag: false,
              centerMode: false,
            },
            1200: {
              itemsToShow: 6,
              centerMode: false,
              mouseDrag: false,
              touchDrag: false,
            }
          }
        },
        current: {
          date: '',
          title: '',
          index: '',
        }
      }
    },
    mounted() {
    },
    methods: {
      onBeforeSlide(slide) {
        // console.log('before slide');
        // console.log(slide);
      },
      onSlide(slide) {
        setTimeout(() => {
          // console.log('slide');
          // console.log(slide);

          const current = this.$el.querySelector('.is-current');
          this.current.date = current.querySelector('.date').textContent;
          this.current.title = current.querySelector('.title').textContent;
          this.$refs.hooper.update();
        }, 100);
      },
      onAfterSlide(slide) {
        // console.log('after slide');
        // console.log(slide);
      },
      slidePrev() {
        this.$refs.hooper.slidePrev();
        this.$refs.hooper.restartTimer();
      },
      slideNext() {
        this.$refs.hooper.slideNext();
        this.$refs.hooper.restartTimer();
      }
    }
  }
</script>
<style lang="scss">
  .mainvisual {
    .hooper {
      height: auto;
      @media (max-width: 640px) {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .hooper-list {
      padding-left: 120px;
      padding-bottom: 40px;
      @media (max-width: 1000px) {
        padding-left: 0;
        padding-bottom: 60px;
      }
    }
    .hooper-slide {
      padding-left: 20px;
      padding-right: 20px;
      @media (min-width: 640px) {
        padding-left: 5px;
        padding-right: 5px;
      }
      @media (min-width: 1000px) {
        padding-left: 0;
        padding-right: 10px;
        transition: opacity .8s ease;
      }
      &.is-prev {
        @media (min-width: 1000px) {
          opacity: .35;
          &:not(.is-active) {
            transition: opacity .8s ease, width .8s ease;
          }
        }
      }
      &.is-current {
        @media (min-width: 1000px) {
          width: calc(100vw / 5 * 2 - 6vw) !important;
        }
        @media (min-width: 1201px) {
          width: calc(100vw / 6 * 2 - 6vw) !important;
        }
      }
    }
    .hooper-pagination {
      position: absolute;
      transform: none;
      justify-content: flex-end;
      right: 0;
      bottom: calc((-1vw + 7em) * -1);
      @media (max-width: 1200px) {
        bottom: 0;
      }
      @media (max-width: 1000px) {
        margin-top: 0;
        right: initial;
        left: 0;
        width: 100%;
        justify-content: center;
        bottom: 10px;
      }
    }
    .hooper-indicator {
      width: 10px;
      height: 10px;
      background-color: #A1A1A1;
      border-radius: 50%;
      margin: 0 6px;
      &:hover, &.is-active {
        background: #008837;
      }
    }
  }
</style>
<style lang="scss" scoped>
  @import '@/scss/foundation/settings';
  @import '@/scss/foundation/functions';
  @import '@/scss/foundation/mixins';
  .mainvisual {
    padding-left: 5.7vw;
    margin-top: 30px;
    padding-bottom: calc(3vw + 24px);
    position: relative;
    @include mq(lg) {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }
    &::before {
      content: '';
      width: 100%;
      height: 13.5vw;
      background: #F5F5F5;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: -1;
      @include mq(sm) {
        height: 120px;
        transform: none;
        top: 22vw;
      }
    }
    img {
      vertical-align: bottom;
      width: 100%;
    }
  }
  .card {
    background: #fff;
    box-shadow: 0 10px 20px rgba(#000, .3);
    @include mq(lg) {
      box-shadow: 0 5px 20px rgba(#000, .15);
    }
    &__inner {
      padding: 15px;
      height: 7em;
      @include mq(sm) {
        display: none;
      }
    }
  }
  .date {
    display: none;
  }
  .title {
    font-weight: bold;
    font-size: rem(15);
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .info {
    margin-top: calc(-6vw + 3em);
    margin-right: 90px;
    @include mq(xl) {
      padding-left: 0;
      margin-top: 0;
    }
    @include mq(lg) {
      padding: 0 20px;
    }
    @include mq(md) {
      margin-right: 0;
    }
    &__inner {
      z-index: -1;
      position: relative;
      padding: 0 20px 0 30px;
      min-height: 5em;
      &::before, &::after {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        top: 0;
      }
      &::before {
        background: map-get($colors, feature);
        left: 0;
      }
      &::after {
        background: map-get($colors, product);
        left: 10px;
      }
    }
    &__date {
      font-size: rem(14);
      @include mq(sm) {
        font-size: rem(13);
      }
    }
    &__title {
      font-size: rem(24);
      font-weight: bold;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      position: absolute;
      @include mq(sm) {
        font-size: rem(16);
        -webkit-line-clamp: 3;
      }
    }
  }
  .hooper-slide {
    &.is-prev {
      .card__inner {
        @include mq_min(lg) {
          display: none;
        }
      }
    }
    &.is-current {
      .card__inner {
        @include mq_min(lg) {
          display: none;
        }
      }
    }
  }
  .controller {
    &__prev,
    &__next {
      width: 50px;
      height: 50px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      background: rgba(#008837, .8);
      border-radius: 50%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      @include mq(sm) {
        width: 30px;
        height: 30px;
        transform: none;
        top: 34vw;
      }
    }
    &__prev {
      left: 20px;
    }
    &__next {
      right: 40px;
      @include mq(lg) {
        right: 20px;
      }
    }
  }
</style>
