import 'webfontloader';
import 'intersection-observer';
import Vue from "vue/dist/vue.esm";
import VModal from "vue-js-modal";
// import Store from "./store/global";
import _ from 'lodash';
import 'lazysizes';
import { gsap, ScrollTrigger } from "gsap/all";
import 'smooth-scroll';
import SmoothScroll from 'smooth-scroll';

gsap.registerPlugin(ScrollTrigger);

window.WebFontConfig = {
    google: {
        families: ['Noto+Sans+JP:400,700']
    }
};

(function() {
    var wf = document.createElement('script');
    wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js';
    wf.type = 'text/javascript';
    wf.async = 'true';
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(wf, s);
})();

document.addEventListener("DOMContentLoaded", () => {
    const scroll = new SmoothScroll('a[href^="#"]');

    Vue.use(require('vue2-touch-events'));
    Vue.use(VModal, { componentName: 'vue-js-modal' });

    Vue.component('nl2br', require('vue-nl2br').default);
    Vue.component('like-btn', require('./vue/component/LikeBtn.vue').default);
    Vue.component('twitter-timeline', require('./vue/component/TwitterTimeline.vue').default);
    Vue.component('modal', require('./vue/component/Modal.vue').default);

    Vue.component('site-header', require('./vue/project/Header.vue').default);
    Vue.component('main-visual', require('./vue/project/MainVisual.vue').default);
    Vue.component('recommend-feature', require('./vue/project/RecommendFeature.vue').default);
    Vue.component('home-column', require('./vue/project/HomeColumn.vue').default);
    Vue.component('product-slide-items', require('./vue/project/ProductSlideItems.vue').default);
    Vue.component('product-categories', require('./vue/project/ProductCategories.vue').default);
    Vue.component('product-images', require('./vue/project/ProductImages.vue').default);
    Vue.component('recipe-slide-items', require('./vue/project/RecipeSlideItems.vue').default);
    Vue.component('share', require('./vue/project/Share.vue').default);
    Vue.component('comment', require('./vue/project/Comment.vue').default);
    Vue.component('item-filter', require('./vue/project/Filter.vue').default);
    Vue.component('item-sort', require('./vue/project/Sort.vue').default);
    Vue.component('faq-accordion', require('./vue/project/FaqAccordion.vue').default);

    new Vue({
        el: '#app',
        // Store,
        data() {
            return {
            }
        },
        mounted() {
        }
    });

    // gsap
    const animation_effect = [
        '--fade-up',
        '--fade-down',
        '--fade-left',
        '--fade-right',
    ];
    const animation_breakpoints = [
        '',
        '-sm',
        '-md',
        '-lg',
        '-xl',
    ];
    let animation_class = [];
    _.forEach(animation_breakpoints, (breakpoint) => {
        _.forEach(animation_effect, (effect) => {
            animation_class.push(`.u-animation${breakpoint}${effect}`);
        });
    });

    animation_class.forEach((selector) => {
        document.querySelectorAll(selector).forEach((el) => {
            ScrollTrigger.create({
                markers: false,
                trigger: el,
                toggleClass: 'is-active',
                start: "top 80%",
                end: "bottom top",
                once: true,
            });
        });
    });

    // 商品イメージがリンク切れな場合Noimageにする
    const productImages = document.querySelectorAll('.c-card--product .c-card__image img, .product-images img');
    productImages.forEach((image) => {
        image.addEventListener('error', () => {
            image.setAttribute('src', '/assets/img/product/img_product_noimage.png');
        });
    });

    // a要素を取得
    const el_a = document.getElementsByTagName('a');
    for (let element of el_a) {
        let target = element.getAttribute('target');

        // target属性が_blankであれば rel属性のnoopenerを付与
        if (target === '_blank') {
            element.setAttribute('rel', 'noopener');

            // noreferrerも付与する場合
            // element.setAttribute('rel', 'noopener noreferrer');
        }
    }
});
