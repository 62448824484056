<template>
  <vue-js-modal
    :name="name"
    :width="'90%'"
    :height="'auto'"
    :maxWidth="760"
    :adaptive="true"
    :scrollable="true"
  >
    <div class="modal">
      <h3 class="c-heading-2" v-if="this.$scopedSlots.title">
        <slot name="title"></slot>
      </h3>
      <div>
        <slot name="content"></slot>
      </div>
      <button class="modal__close" @click="modalClose()">&times;</button>
    </div>
  </vue-js-modal>
</template>
<script>
  export default {
    props: {
      name: String
    },
    methods: {
      modalClose() {
        this.$modal.hide(this.name);
      }
    }
  }
</script>
<style lang="scss">
  @import '@/scss/foundation/settings';
  @import '@/scss/foundation/functions';
  @import '@/scss/foundation/mixins';
  .vm--modal {
    height: auto !important;
  }
  .vm--container {
      z-index: 1001 !important;
  }
  .modal {
    padding: 40px;
    position: relative;
    @include mq(sm) {
      padding: 20px;
    }
    &__close {
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #222;
      color: #fff;
      font-size: rem(24);
      position: absolute;
      right: 0px;
      top: 0px;
      border-left: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      @include mq_min(lg) {
        transition: transform .3s ease(OutBack);
        transform-origin: top right;
        &:hover, &:focus {
          transform: scale(1.1);
        }
      }
      @include mq(sm) {
        width: 40px;
        height: 40px;
        font-size: rem(18);
      }
    }
  }
</style>
