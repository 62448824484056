<template>
  <div class="product-slide-items">
    <div class="product-slide-items__pc">
      <div class="product-slide-items__layout" :class="{'-bg':bg}">
        <div v-for="(item, i) in items" :key="i" class="c-card--product" :class="{'-bg':bg}">
          <a class="c-card__inner -scale-image" :class="{'-bg -shadow':bg}" :href="item.url">
            <figure class="c-card__image">
              <img v-if="item.image" :src="item.image" :alt="item.image_alt ? item.image_alt : item.name">
              <img v-else src="/assets/img/product/img_product_noimage.png" :alt="item.name">
            </figure>
            <div>
              <p class="product-slide-items__name">{{ item.name }}</p>
              <p v-if="item.price" class="product-slide-items__price">{{ item.price }}円
                <span v-if="item.price_range_flg === 1">（税込）〜</span>
                <span v-else>（税込）</span>
              </p>
            </div>
            <ul class="l-setting-tags u-mt-20">
              <li v-if="item.new_flg" class="l-setting-tags__item -new u-fs-10">
                <img src="/assets/img/recommend/icon_new.png" alt="新商品">
                新商品
              </li>
              <li v-if="item.recommend_flg" class="l-setting-tags__item u-fs-10">
                <img src="/assets/img/recommend/icon_recommend.png" alt="おすすめ">
                おすすめ
              </li>
              <li v-if="item.tv_flg" class="l-setting-tags__item u-fs-10">
                <img src="/assets/img/recommend/icon_tv.png" alt="TVで話題">
                TVで話題
              </li>
              <li v-if="item.sns_flg" class="l-setting-tags__item u-fs-10">
                <img src="/assets/img/recommend/icon_talk.png" alt="SNSで話題">
                SNSで話題
              </li>
            </ul>
          </a>
          <div class="c-card__likebtn">
            <like-btn :number="item.like.number" :product-id="item.id"></like-btn>
          </div>
        </div>
      </div>
    </div>
    <div class="product-slide-items__sp">
      <hooper ref="hooper" :settings="hooperSettings" @beforeSlide="onBeforeSlide" @slide="onSlide" @afterSlide="onAfterSlide">
        <slide v-for="(item, i) in items" :key="i">
          <div class="c-card--product" :class="(bg) ? '-bg' : 'product-slide-border'">
            <a class="c-card__inner -scale-image" :class="{'-bg -shadow':bg}" :href="item.url">
              <img v-if="item.image" :src="item.image" :alt="item.image_alt ? item.image_alt : item.name">
              <img v-else src="/assets/img/product/img_product_noimage.png" :alt="item.name">
              <div class="card__inner">
                <p class="product-slide-items__name">{{ item.name }}</p>
                <p class="product-slide-items__price">{{ item.price }}円<span>（税込）</span></p>
              </div>
              <ul class="l-setting-tags u-mt-20">
                <li v-if="item.new_flg" class="l-setting-tags__item -new u-fs-10">
                  <img src="/assets/img/recommend/icon_new.png" alt="新商品">
                  新商品
                </li>
                <li v-if="item.recommend_flg" class="l-setting-tags__item u-fs-10">
                  <img src="/assets/img/recommend/icon_recommend.png" alt="おすすめ">
                  おすすめ
                </li>
                <li v-if="item.tv_flg" class="l-setting-tags__item u-fs-10">
                  <img src="/assets/img/recommend/icon_tv.png" alt="TVで話題">
                  TVで話題
                </li>
                <li v-if="item.sns_flg" class="l-setting-tags__item u-fs-10">
                  <img src="/assets/img/recommend/icon_talk.png" alt="SNSで話題">
                  SNSで話題
                </li>
              </ul>
            </a>
            <div class="c-card__likebtn">
              <like-btn :number="item.like.number" :product-id="item.id"></like-btn>
            </div>
          </div>
        </slide>
        <hooper-pagination slot="hooper-addons" mode="fraction"></hooper-pagination>
      </hooper>
      <div class="controller">
        <button type="button" class="controller__prev" @click.prevent="slidePrev">
          <i class="icon-left" />
        </button>
        <button type="button" class="controller__next" @click.prevent="slideNext">
          <i class="icon-right" />
        </button>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    Hooper,
    Slide,
    Pagination as HooperPagination
  } from 'hooper';

  export default {
    props: {
      items: Array,
      bg: {
        type: Boolean,
        default: false,
      }
    },
    components: {
      Hooper, Slide, HooperPagination
    },
    data() {
      return {
        hooperSettings: {
          itemsToShow: 2,
          infiniteScroll: true,
          autoPlay: true,
          mouseDrag: false,
          touchDrag: true,
          wheelControl: false,
          keysControl: false,
          playSpeed: 6000,
          transition: 800,
          hoverPause: false,
          centerMode: true,
        },
        current: {
          date: '',
          title: '',
          index: '',
        }
      }
    },
    mounted() {
    },
    methods: {
      onBeforeSlide(slide) {
      },
      onSlide(slide) {
      },
      onAfterSlide(slide) {
      },
      slidePrev() {
        this.$refs.hooper.slidePrev();
        this.$refs.hooper.restartTimer();
      },
      slideNext() {
        this.$refs.hooper.slideNext();
        this.$refs.hooper.restartTimer();
      }
    }
  }
</script>
<style lang="scss">
  .product-slide-items__sp {
    .hooper * {
      &:focus {
        outline: none;
      }
    }
    .hooper-slide {
      padding-left: 5px;
      padding-right: 5px;
      height: auto;
    }
    .hooper-list {
      padding-bottom: 60px;
    }
    .hooper-indicator {
      width: 10px;
      height: 10px;
      background-color: #A1A1A1;
      border-radius: 50%;
      margin: 0 6px;
      &:hover, &.is-active {
        background: #008837;
      }
    }
  }
</style>
<style lang="scss" scoped>
  @import '@/scss/foundation/settings';
  @import '@/scss/foundation/functions';
  @import '@/scss/foundation/mixins';
  .product-slide-items {
    position: relative;
    &__pc {
      @include mq(sm) {
        display: none;
      }
    }
    &__sp {
      display: none;
      @include mq(sm) {
        display: block;
      }
    }
    &__layout {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: auto;
      row-gap: 50px;
      column-gap: 50px;
      @include mq(lg) {
        grid-template-columns: repeat(3, 1fr);
      }
      @include mq(sm) {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 10px;
        column-gap: 10px;
      }
      &.-bg {
        column-gap: 20px;
        row-gap: 20px;
      }
    }
    &__name {
      font-size: rem(16);
      font-weight: bold;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-top: 10px;
      @include mq(sm) {
        font-size: rem(14);
      }
    }
    &__price {
      font-size: rem(14);
      margin-top: 10px;
      & > span {
        font-size: rem(12);
      }
    }
  }
  .product-slide-border {
    @include mq(sm) {
      border: 1px solid #e5e5e5;
      &::after {
        right: 5px;
        bottom: 5px;
      }
    }
  }
  .c-card {
    &__inner {
      padding: 0 0 40px;
      @include mq(sm) {
        padding: 15px 15px 40px;
      }
      &.-bg {
        padding: 25px 25px 40px;
      }
    }
    &--product {
      @include mq(sm) {
        height: 100%;
      }
    }
    &__likebtn {
      @include mq(sm) {
        top: -20px;
      }
    }
  }
  .hooper {
    height: auto;
    @include mq(sm) {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .hooper-pagination {
    font-size: rem(13);
  }
  .controller {
    &__prev,
    &__next {
      width: 50px;
      height: 50px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      background: rgba(#008837, .8);
      border-radius: 50%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      @include mq(sm) {
        width: 40px;
        height: 40px;
        transform: none;
        top: 34vw;
      }
    }
    &__prev {
      left: 2%;
    }
    &__next {
      right: 2%;
    }
  }
</style>
