<template>
    <ul class="l-sort-link -product list-unstyled">
        <li class="l-sort-link__item">
            <span v-if="!this.sort_item || this.sort_item === 'order_new'">新着順</span>
            <a v-else @click.stop="sortChanged('order_new')" href="#">新着順</a>
        </li>
        <li class="l-sort-link__item">
            <span v-if="this.sort_item === 'order_like'">人気順</span>
            <a v-else @click.stop="sortChanged('order_like')" href="#">人気順</a>
        </li>
        <li class="l-sort-link__item">
            <span v-if="this.sort_item === 'order_low_price'">価格が低い順</span>
            <a v-else @click.stop="sortChanged('order_low_price')" href="#">価格が低い順</a>
        </li>
        <li class="l-sort-link__item">
            <span v-if="this.sort_item === 'order_high_price'">価格が高い順</span>
            <a v-else @click.stop="sortChanged('order_high_price')" href="#">価格が高い順</a>
        </li>
    </ul>
</template>
<script>
export default {
    data() {
        return {
            queryname: 'sort_item',
            sort_item: 'order_new'
        }
    },
    mounted() {
        const url = new URL(window.location.href);
        let urlParam = url.search.substring(1);
        if (urlParam) {
            // 「&」で分割
            let param = urlParam.split('&');
            let paramArray = [];
            for (let i = 0; i < param.length; i++) {
                let paramItem = param[i].split('=');
                paramArray[paramItem[0]] = paramItem[1];
            }
            this.sort_item = paramArray.sort_item;
        }
    },
    methods: {
        sortChanged(type) {
            const url = new URL(window.location.href);
            url.searchParams.delete(this.queryname);
            if (type !== '') {
                url.searchParams.append(this.queryname, type);
            }
            window.location.href = url;
        }
    }
}
</script>
