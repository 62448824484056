<template>
  <header class="p-header" :class="{ '-shadow' : shadow, '-border' : border }">
    <div class="l-inner">
      <div class="l-inner__item--logo">
        <h1 v-if="top_flg" class="p-header__logo">
          <a href="/"><img src="/assets/img/common/logo.svg" alt="セブンプレミアム"></a>
        </h1>
        <div v-else class="p-header__logo">
          <a href="/"><img src="/assets/img/common/logo.svg" alt="セブンプレミアム"></a>
        </div>
      </div>
      <nav class="l-inner__item--nav" :class="{'is-hide':search, 'is-show': menu }" v-touch:swipe.left="closeMenu">
        <ul class="p-header-nav">
          <li v-for="(page, i) in pages" :key="i" class="p-header-nav__item">
            <a :class="`color--${page.color}`" :href=page.url>{{ page.name }}</a>
          </li>
        </ul>
      </nav>
      <div class="l-inner__item--search-toggle">
        <button class="search-toggle" @click="searchToggle">
          <i :class="(search) ? 'icon-close' : 'icon-search'" />
        </button>
      </div>
      <div class="l-inner__item--menu-toggle">
        <button class="menu-toggle" @click="menuToggle">
          <i :class="(menu) ? 'icon-close' : 'icon-bars'" />
        </button>
      </div>
    </div>
    <div class="l-inner__item--search" :class="{'is-show':search }">
      <form :action="search_url">
        <div class="c-input-group-append">
          <input type="text" v-model="search_word" name="search_word" class="c-form-control" placeholder="キーワードを入力" ref="searchInput">
        </div>
        <div class="c-button-group">
          <button class="c-btn c-btn--product" type="submit" @click.stop="setUrl(1)"> 商品を探す</button>
          <button class="c-btn c-btn--recipe" type="submit" @click.stop="setUrl(2)"> レシピを探す</button>
        </div>
      </form>
    </div>
  </header>
</template>

<script>
export default {
  props: {
    shadow: {
      type: Boolean,
      default: false,
    },
    border: {
      type: Boolean,
      default: false,
    },
    links: {
      type: Object,
      required: true
    },
    search_value: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      pages: [
        { name: 'セブンプレミアムとは', url: this.links.about, color: 'default' },
        { name: '商品情報', url: this.links.product, color: 'product' },
        { name: '特集', url: this.links.pickup, color: 'feature' },
        { name: 'レシピ', url: this.links.recipe, color: 'recipe' },
        { name: '記事', url: this.links.blog, color: 'article' },
        { name: 'お知らせ', url: this.links.news, color: 'default' },
      ],
      search: false,
      search_url: null,
      search_word: null,
      menu: false,
      top_flg: false
    }
  },
  mounted() {
    if (location.pathname === '/') {
      // TOPページの場合、ロゴをh1タグに置き換える
      this.top_flg = true;
    }
    this.search_word = this.search_value;
  },
  methods: {
    searchToggle() {
      this.search = !this.search;
      if(this.search === true) {
        setTimeout(() => {
          this.$refs.searchInput.focus();
        }, 100);
      }
    },
    menuToggle() {
      this.menu = !this.menu;
      document.body.classList.toggle('menu-open');
    },
    closeMenu() {
      this.menu = false;
      document.body.classList.remove('menu-open');
    },
    setUrl(flag) {
      switch (flag) {
        case 1:
        default:
          this.search_url = this.links.product_search;
          break;
        case 2:
          this.search_url = this.links.recipe_search;
          break;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/scss/foundation/settings';
  @import '@/scss/foundation/functions';
  @import '@/scss/foundation/mixins';
  .l-inner {
    width: 1340px;
    max-width: 100%;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-flow: row wrap;
    @include mq(lg) {
      align-items: initial;
    }
    &__item {
      &--logo {
        flex: 0 0 330px;
        padding: 0 20px 0 0;
        @include mq(xl) {
          flex: 0 0 300px;
          padding: 0 20px;
        }
        @include mq(lg) {
          padding: 10px 20px;
          flex: 0 0 260px;
        }
        @include mq(sm) {
          flex: 0 1 250px;
          padding: 10px 15px;
        }
        @media (max-width: 380px) {
          flex: 0 1 60vw;
          align-self: center;
        }
      }
      &--nav {
        flex: 0 0 auto;
        padding-left: 20px;
        margin-left: auto;
        @include mq(lg) {
          opacity: 0;
          pointer-events: none;
          position: fixed;
          top: 0;
          left: 0;
          background: rgba(#fff, .9);
          width: calc(100vw - 60px);
          height: 100vh;
          padding-left: 0;
          box-shadow: 0 10px 20px rgba(#000, .1);
          backdrop-filter: blur(3px);
          transform: translateX(-100%);
          transition:.3s;
          z-index: 1000;
          &.is-show {
            opacity: 1;
            pointer-events: initial;
            transform: translateX(0);
          }
        }
      }
      &--search {
        order: 4;
        pointer-events: none;
        opacity: 0;
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        flex: 0 0 100%;
        padding: 0 20px;
        .c-input-group-append {
          transform: translateY(-10%);
        }
        .c-form-control {
          font-size: 1rem;
          border-right: 1px solid #ddd;
          border-radius: 60px;
          padding: 22px 49px;
          @include mq(xl) {
            padding: 17px 26px;
          }
        }
        .c-button-group {
          display: flex;
          justify-content: center;
          margin-top: 24px;
          column-gap: 22px;
          @include mq(xl) {
            margin-top: 19px;
            column-gap: 10px;
          }
          .c-btn {
            width: auto;
            min-width: 280px;
            @include mq(sm) {
              width: 50%;
              min-width: auto;
            }
          }
        }
        &.is-show {
          border-top: 1px solid #e5e5e5;
          padding: 50px 20px 60px;
          opacity: 1;
          position: relative;
          pointer-events: initial;
          transition: none;
          @include mq(xl) {
            padding: 36px 15px;
          }
          > form {
            width: 1080px;
            max-width: 100%;
            margin-left: auto;
            margin-right: auto;
            @include mq(lg) {
              width: 100%;
            }
          }
          .c-input-group-append {
            transform: translateY(0);
            transition: opacity .3s, transform .3s;
          }
        }
      }
      &--search-toggle {
        flex: 0 0 60px;
        min-height: 60px;
        @include mq(lg) {
          border-left: 1px solid #e5e5e5;
          margin-left: auto;
        }
      }
      &--menu-toggle {
        display: none;
        @include mq(lg) {
          display: block;
          flex: 0 0 60px;
          min-height: 60px;
        }
      }
    }
  }
  .p-header {
    background: #F9F9F9;
    &.-shadow {
      box-shadow: 0 10px 30px rgba(#000, .05);
    }
    &.-border {
      border-bottom: 1px solid #E5E5E5;
    }

    &__logo {
      font-size: 1rem;
      margin: 0;
      line-height: 1;
      img {
        width: 100%;
      }
    }
    &-nav {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      @include mq(lg) {
        display: block;
      }
      &__item {
        a {
          color: map-get($map: $colors, $key: 'body');
          text-decoration: none;
          display: flex;
          align-items: center;
          padding: 35px 0;
          margin: 0 15px;
          font-weight: bold;
          font-feature-settings: "palt" 1;
          @include mq_min(lg) {
            position: relative;
            &::after {
              content: '';
              position: absolute;
              width: 100%;
              height: 3px;
              bottom: 0;
              left: 0;
              transform: scale(0, 1);
              transform-origin: right top;
              transition: transform .3s cubic-bezier(0, 0.55, 0.45, 1);
            }
            &:hover, &:focus {
              &::after {
                transform: scale(1, 1);
                transform-origin: left top;
              }
            }
            &.color {
              &--product {
                &::after {
                  background: map-get($map: $colors, $key: product);
                }
              }
              &--feature {
                &::after {
                  background: map-get($map: $colors, $key: feature);
                }
              }
              &--recipe {
                &::after {
                  background: map-get($map: $colors, $key: recipe);
                }
              }
              &--article {
                &::after {
                  background: map-get($map: $colors, $key: article);
                }
              }
              &--default {
                &::after {
                  background: map-get($map: $colors, $key: body);
                }
              }
            }
          }
          @include mq(lg) {
            margin: 0;
            padding: 15px;
            border-bottom: 1px solid #e5e5e5;
          }
          @include mq(sm) {
            font-size: rem(14);
          }
        }
      }
    }
  }
  .search-toggle,
  .menu-toggle {
    width: 100%;
    height: 100%;
    font-size: 20px;
    padding: 30px 0;
    @include mq(lg) {
      padding: 0;
    }
  }
  .menu-toggle {
    background: #EF8200;
    color: #fff;
  }
  @include mq(lg) {
    body.menu-open {
      height: 100vh;
      overflow: hidden;
    }
  }
</style>
