<template>
  <div class="column">
    <hooper ref="hooper" :settings="hooperSettings" @beforeSlide="onBeforeSlide" @slide="onSlide" @afterSlide="onAfterSlide">
      <slide v-for="(slide, i) in items" :key="i">
        <div class="c-card--recipe">
          <a :href="slide.url" class="c-card__inner -scale-image">
            <figure class="c-card__image">
              <img v-if="slide.image_alt" :src="slide.image" :alt="slide.image_alt">
              <img v-else :src="slide.image" :alt="slide.title">
            </figure>
            <div class="card__inner">
              <p class="u-fs-12 u-fs-sm-14 u-mt-10">{{slide.date}}</p>
              <p class="u-fs-16 u-fs-md-18 u-text--bold">{{slide.title}}</p>
            </div>
          </a>
        </div>
      </slide>
      <hooper-pagination slot="hooper-addons"></hooper-pagination>
    </hooper>
    <div class="controller">
      <button type="button" class="controller__prev" @click.prevent="slidePrev">
        <i class="icon-left" />
      </button>
      <button type="button" class="controller__next" @click.prevent="slideNext">
        <i class="icon-right" />
      </button>
    </div>
  </div>
</template>
<script>
  import {
    Hooper,
    Slide,
    Pagination as HooperPagination
  } from 'hooper';

  export default {
    props: {
      items: Array
    },
    components: {
      Hooper, Slide, HooperPagination
    },
    data() {
      return {
        hooperSettings: {
          itemsToShow: 1,
          infiniteScroll: true,
          autoPlay: false,
          mouseDrag: false,
          touchDrag: true,
          wheelControl: false,
          keysControl: false,
          playSpeed: 6000,
          transition: 800,
          hoverPause: false,
          centerMode: true,
          breakpoints: {
            640: {
              itemsToShow: 2,
              itemsToSlide: 1,
            },
            1000: {
              itemsToShow: 4,
              itemsToSlide: 1,
              centerMode: false,
            },
          }
        },
        current: {
          date: '',
          title: '',
          index: '',
        }
      }
    },
    mounted() {
    },
    methods: {
      onBeforeSlide(slide) {
      },
      onSlide(slide) {
      },
      onAfterSlide(slide) {
      },
      slidePrev() {
        this.$refs.hooper.slidePrev();
        this.$refs.hooper.restartTimer();
      },
      slideNext() {
        this.$refs.hooper.slideNext();
        this.$refs.hooper.restartTimer();
      }
    }
  }
</script>
<style lang="scss">
  .column {
    .hooper-slide {
      padding-left: 5px;
      padding-right: 5px;
      @media (max-width: 640px) {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .hooper-list {
      padding-bottom: 50px;
      @media (max-width: 1000px) {
        padding-left: 0;
        padding-bottom: 60px;
      }
    }
    .hooper-indicator {
      width: 10px;
      height: 10px;
      background-color: #A1A1A1;
      border-radius: 50%;
      margin: 0 6px;
      &:hover, &.is-active {
        background: #E3872C;
      }
    }
  }
</style>
<style lang="scss" scoped>
  @import '@/scss/foundation/settings';
  @import '@/scss/foundation/functions';
  @import '@/scss/foundation/mixins';
  .column {
    position: relative;
  }
  .hooper {
    height: auto;
    @include mq(sm) {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .hooper-pagination {
    font-size: rem(13);
  }
  .c-card {
    &__inner {
      padding: 0 0 40px;
    }
  }
  .card__inner {
    @include mq(sm) {
      padding: 0 15px;
    }
  }
  .controller {
    &__prev,
    &__next {
      width: 50px;
      height: 50px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: #E3872C;
      background: rgba(#fff, .9);
      border-radius: 50%;
      position: absolute;
      top: 30%;
      box-shadow: 0 10px 10px rgba(#000, .1);
      @include mq(sm) {
        width: 40px;
        height: 40px;
        transform: none;
        top: 34vw;
      }
    }
    &__prev {
      left: 0;
      transform: translateY(-50%) translateX(-50%);
      @include mq(xl) {
        left: 20px;
        transform: translateY(-50%);
      }
    }
    &__next {
      right: 0;
      transform: translateY(-50%) translateX(50%);
      @include mq(xl) {
        right: 20px;
        transform: translateY(-50%);
      }
    }
  }
</style>
