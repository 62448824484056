<template>
  <div class="product-images">
    <figure class="main-image">
      <transition name="fade" mode="out-in">
        <img :src="checkedImages[activeIndex]" :key="checkedImages[activeIndex]" :alt="itemName">
      </transition>
    </figure>
    <ul class="thumbnail list-unstyled">
      <li class="thumbnail__item" v-for="(image, i) in checkedImages" :key="i">
        <button @click="changeImage(i)" class="thumbnail__btn" :class="{'is-active': activeIndex === i}">
          <img :src="image" :alt="imageAlt[i] != null ? imageAlt[i] : itemName + ' - サムネイル' + (i+1)">
        </button>
      </li>
      <li class="thumbnail__item" v-if="object.src.length > 0">
        <button class="thumbnail__btn" @click="modalShow">
          <img src="/assets/img/product/img_thumb_3d.png" alt="3Dで見る">
        </button>
      </li>
    </ul>
    <modal v-if="object.src.length > 0" name="3dview">
      <template slot="content">
        <div class="content-3d">
          <!-- <div v-html="object.src"></div> -->
          <three-canvas :ossPath="object.src"></three-canvas>
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
  import ThreeCanvas from '../component/ThreeCanvas.vue';
  export default {
    props: {
      images: Array,
      itemName: {
        type: String,
        default: null
      },
      imageAlt: Array,
      object: Object
    },
    components: {
      ThreeCanvas
    },
    data() {
      return {
        activeIndex: 0,
        checkedImages: [],
      }
    },
    mounted() {
      // 商品画像未設定の場合、Noimageを表示
      if (!this.images.length) {
        this.checkedImages.push('/assets/img/product/img_product_noimage.png');
      }
    },
    created() {
      const checkImageExists = (path) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = path;
          img.onload = () => resolve(path);
          img.onerror = () => reject();
        });
      };
      (async () => {
        for await (const image of this.images) {
          await checkImageExists(image)
            .then((path) => {
              this.checkedImages.push(path);
            })
            .catch(() => {
              this.checkedImages.push('/assets/img/product/img_product_noimage.png');
            })
          }
      })();
    },
    methods: {
      changeImage(index) {
        this.activeIndex = index;
      },
      modalShow() {
        this.$modal.show('3dview');
      }
    }
  }
</script>
<style lang="scss" scoped>
  @import '@/scss/foundation/settings';
  @import '@/scss/foundation/functions';
  @import '@/scss/foundation/mixins';
  .product-images {
    @include mq(md) {
      display: flex;
      align-items: flex-start;
      column-gap: 20px;
    }
    @include mq(sm) {
      display: block;
    }
  }
  .main-image {
    width: 100%;
    padding-bottom: 100%;
    position: relative;
    img {
      position: absolute;
      max-width: 100%;
      max-height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
    @include mq(md) {
      flex: 0 0 400px;
      padding-bottom: 400px
    }
    @include mq(sm) {
      flex: 0 1 345px;
      padding-bottom: 345px
    }
  }
  .thumbnail {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 12px;
    row-gap: 12px;
    margin-top: 12px;
    @include mq(md) {
      grid-template-columns: repeat(2, 1fr);
      margin-top: 0;
    }
    @include mq(sm) {
      grid-template-columns: repeat(4, 1fr);
      margin-top: 12px;
    }
    &__btn {
      border: 1px solid #ccc;
      &.is-active {
        border-color: #E3872C;
      }
    }
  }
  .fade {
    &-enter {
      opacity: 0;
      &-active {
        transition: opacity .5s;
      }
      &-to {
        opacity: 1;
      }
    }
    &-leave {
      opacity: 1;
      &-active {
        transition: opacity .1s;
      }
      &-to {
        opacity: 0;
      }
    }
  }
</style>
<style lang="scss">
  .content-3d {
    iframe {
      width: 100%;
      height: auto;
      aspect-ratio: 16/9;
    }
  }
</style>
